import React, { useContext, useEffect } from "react";

import { ConfigStateContext } from "../../../Contexts/Configurations/context";
import Cookies from "js-cookie";
import { useUser } from "../User.hook";
import getConfig from 'next/config'

export const Logout = ({className=''}) => {
	const { logout } = useUser();
	const { country_code } = useContext(ConfigStateContext);
	const { loginBasePath, ovPath } = getConfig().publicRuntimeConfig;
	
	useEffect(() => {
		if (logout.response.data && !logout.response.loading) {
			Cookies.remove("user_info", { domain: loginBasePath, path: "/" });
			Cookies.remove("frontend_token", { domain: loginBasePath, path: "/" });
			localStorage.removeItem("user_md5"); 
			localStorage.removeItem("one_tap_logged");
			localStorage.removeItem('user')
			localStorage.removeItem('contactForm')
			localStorage.removeItem('selectedCountryCode')

			if (country_code === "BR") {
				window.location.reload();
			}
			else if(country_code === "CO"){
				window.location.assign('/?logout=true')
			}
			else window.location.assign("/sitio/index.php?mid=login&func=logout");
		}
	}, [logout.response]);

	return (
		<div className={className}
			onClick={() => {
				if (!logout.response.called) logout.send();
			}}>
			{"Cerrar Sesión"}
		</div>
	);
};
