import React, { ReactNode, useEffect } from 'react';
import './styles.less';
import { CloseButton } from '../CustomIcons/CloseButton/web';
import { createPortal } from 'react-dom';

interface ModalProps {
    show: boolean;
    width?: string;
    height?: string;
    background?: string;
    title?: string | ReactNode;
    footer?: ReactNode;
    showHeader?: boolean;
    children: React.ReactNode;
    onClose: () => void;
    classes?: string
    customIndex?: number
}

export const Modal: React.FC<ModalProps> = ({ show, width, height, background, title, children, onClose, footer, classes, showHeader = true, customIndex }) => {
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Escape") {
                handleClose();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const handleClose = () => {
        onClose();
    };

    const modalStyle = {
        width: width || 'auto',
        height: height || 'auto',
    };
    return show ? createPortal(
        <div className={`modal-backdrop modal-open`} style={{ zIndex: customIndex ?? 999 }}>
            <div className={"modal " + (background == 'transparent ' ? 'modal-transparent ' : '') + (classes ?? "")} style={modalStyle}>
                {showHeader && (<div className="modal-header">
                    <div className="modal-title">{title}</div>
                    <button className={"close-button " + (background == 'transparent' ? 'button-background' : '')} onClick={handleClose}>
                        <CloseButton />
                    </button>
                </div>)}
                <div className="modal-body">
                    {children}
                </div>

                {footer && <div className="modal-footer">
                    {footer}
                </div>}
            </div>
        </div>,
        document.body
    ) : null;

};
