import "./styles.less";

import React, { FC } from "react";
import { ImagenOptimizada } from "../../Image/_new_web";
import { Tooltip } from "shared-components/Components/Tooltip/web";
import { CheckIcon } from "shared-components/Components/CustomIcons/CheckIcon/web";


interface OwnerLogoProps {
	loading?: boolean;
	inmoLink?: string;
	inmoPropsLink?: string;
	logo: string;
	name: string;
	type?: "full" | "small";
	inmoFull?: boolean;
}

export const OwnerLogo: FC<OwnerLogoProps> = ({
	loading = false,
	inmoLink = null,
	inmoPropsLink = null,
	logo,
	name,
	type = "small",
	inmoFull = false,
}) => {
	if (loading) return <></>;

	return (
		<OwnerLogoWrapper link={inmoLink || inmoPropsLink} name={name} >
			<div className="property-owner-logo">
				<div style={{position: 'relative'}}>
					<ImagenOptimizada src={logo} alt={name} width={110} height={50} />
					{inmoFull && type == "small" && 
						<Tooltip text="Inmobiliaria Verificada" className="verificado">
							<CheckIcon />
						</Tooltip>
					}
				</div>
					
					{type == "full" && (
						<div style={{ display: "flex", flexDirection: "column", justifyContent: "center"}}>
							<strong  className="owner-name-text" style={{color:"#000", fontWeight:"bold"}}>{name}</strong>
							{inmoFull && (
								<div className="verificadoFull">
									<CheckIcon />
									{"Inmobiliaria Verificada"}
								</div>
							)}
						</div>
					)}
			</div>
		</OwnerLogoWrapper>
	);
};



const OwnerLogoWrapper = ({ link, name, children }) => link
	? <a href={link} target="_blank" title={`${name}`}>{children}</a>
	: <>{children}</>;
