import { gql } from "@apollo/client";
// import { property } from "./partials";

export const QUERY_REAL_ESTATE_AGENTS = gql`
	query RealEstateAgents {
		inmofull: realEstateAgents(premium_agents: true, type: "inmobiliaria") {
			id
			type
			name
			logo_v2
			logo
			profile_url
			description
			color
			font_color
			img_cover
			inmoPropsLink
			masked_phone
			has_whatsapp
			address
			subsidiaries {
				id
				name
				address
				email
			}
			properties(first: 3) {
				data {
					id
					isExternal
					link
					img
				}
			}
		}

		notInmoFull: realEstateAgents(premium_agents: false, type: "inmobiliaria") {
			id
			type
			name
			logo
			inmoPropsLink
			address
			masked_phone
		}
	}
`;

export const QUERY_DEVELOPERS = gql`
	query RealEstateAgents {
		inmofull: realEstateAgents(premium_agents: true, type: "desarrollador") {
			id
			type
			name
			logo_v2
			logo
			profile_url
			description
			color
			font_color
			img_cover
			inmoPropsLink
			masked_phone
			has_whatsapp
			address
			subsidiaries {
				id
				name
				address
				email
			}
			properties(first: 3) {
				data {
					id
					isExternal
					link
					img
				}
			}
		}

		notInmoFull: realEstateAgents(premium_agents: false, type: "desarrollador") {
			id
			type
			name
			logo
			inmoPropsLink
			address
			masked_phone
		}
	}
`;